<template>
  <a-modal
    class="sims-create-announcement-modal"
    centered
    v-model="showModal"
    title="New Announcement"
  >
    <template slot="footer">
      <a-button v-if="stage === 0" key="back" @click="$emit('close-modal')">
        Cancel
      </a-button>
      <a-button
        v-if="stage === 0"
        :disabled="!canAnnounce"
        key="submit"
        type="primary"
        @click.prevent="goToNextStage"
      >
        Next
      </a-button>

      <a-button
        v-if="stage === 1"
        key="back"
        @click="goBack"
        :disabled="isSaving"
      >
        Back
      </a-button>
      <a-button
        v-if="stage === 1"
        key="submit"
        :loading="isSaving"
        type="primary"
        @click.prevent="persist"
      >
        Create Announcement
      </a-button>
    </template>

    <!-- Stage 0 -->
    <div class="inner" v-if="stage == 0">
      <!-- Organisation selector -->
      <a-tabs
        :class="{ hidden: organisations.length == 1 }"
        @change="selectOrgById"
        :activeKey="selectedOrganisation.id"
      >
        <a-tab-pane
          v-for="organisation in organisationsICanCreateAnnouncementsFor"
          :key="organisation.id"
        >
          <template slot="tab">
            <organisation-thumbnail
              class="org-thumbnail"
              :tenantId="getOrgTenantId(organisation)"
              :orgId="organisation.id"
              size="small"
            ></organisation-thumbnail>
            {{ organisation.displayName }}
          </template>
        </a-tab-pane>
        <a-tab-pane
          v-for="organisation in organisationsIAmNotAnAdminFor"
          disabled
          :key="organisation.id"
          :tab="organisation.displayName"
        >
        </a-tab-pane>
      </a-tabs>
      <!-- / Organisation selector -->

      <!-- Must have org selected -->
      <div v-show="selectedOrganisation">
        <!-- Group selector -->
        <div>
          <a-form-item label="Group" style="margin-bottom: 7px">
            <a-select
              v-model="selectedGroupId"
              :disabled="isLoadingGroups"
              :loading="isLoadingGroups"
              size="large"
              class="group-selector"
            >
              <a-select-option
                v-for="group in groupDropdownOptions"
                :value="group.id"
                :key="group.id"
              >
                {{ group.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <!-- / Group selector -->

        <!-- Announcement selector -->
        <div style="margin-top: 5px">
          <a-form-item label="Announcement Type" style="margin-bottom: 7px">
            <a-select
              v-model="announcementTypeName"
              :disabled="isLoadingAnnouncementPresets"
              :loading="isLoadingAnnouncementPresets"
              size="large"
              class="group-selector"
            >
              <a-select-option
                v-for="preset in announcementPresets"
                :value="preset.displayName"
                option-label-prop="displayName"
                :key="preset.id"
              >
                {{ preset.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-alert
            v-if="announcementTypeDescription"
            :message="announcementTypeDescription"
            type="info"
            show-icon
          />
        </div>
        <!-- / Announcement selector -->

        <!-- Guidance selector -->
        <select-guidance-modal
          :tenant-id="tenantIdForSelectedOrg"
          :org-id="selectedOrganisation.id"
          @set-guidance="setGuidance"
          :visible="selectGuidanceModalVisible"
          @close-modal="closeSelectGuidanceModal"
        ></select-guidance-modal>

        <div v-if="isSharingGuidance" style="margin-top: 5px">
          <a-form-item label="Guidance" style="margin-bottom: 7px">
            <div class="guidance-pack-selector">
              <a-button @click.prevent="openSelectGuidanceModal">
                {{
                  selectedGuidancePackId ? "Change guidance" : "Select guidance"
                }}
              </a-button>
            </div>
          </a-form-item>
        </div>
        <!-- / Guidance selector -->

        <!-- Title -->
        <div style="margin-top: 5px">
          <a-form-item label="Title" style="margin-bottom: 7px">
            <a-input size="large" v-model="title"></a-input>
          </a-form-item>
        </div>
        <!-- / Title -->

        <!-- Description -->
        <div style="margin-top: 5px">
          <a-form-item label="Description" style="margin-bottom: 7px">
            <a-textarea size="large" v-model="description"></a-textarea>
          </a-form-item>
        </div>
        <!-- / Description -->

        <!-- Urgent -->
        <div class="urgent-wrapper">
          <span class="urgent-label">Urgent</span>
          <a-switch v-model="urgent" />
        </div>
        <!-- / Urgent -->
      </div>
      <!-- / Must have org selected -->
    </div>
    <!-- / Stage 0 -->

    <!-- Stage 1 -->
    <div class="inner" v-if="stage == 1">
      <p class="stage-1-first-p">
        This will be sent to {{ groupMembers.length }}
        {{ groupMembers.length == 1 ? "colleague" : "colleagues" }}.
      </p>
      <p>Colleagues can respond with {{ choicesFormatted }}.</p>
    </div>
    <!-- / Stage 1 -->
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import organisationHelpers from "../../../helpers/organisations";
import announcements from "../../../api/announcements";
import timelines from "../../../api/timelines";
const _ = require("lodash");
import chats from "../../../api/chats";
import OrganisationThumbnail from "../../Organisations/OrganisationThumbnail.vue";
import SelectGuidanceModal from "../../Announcements/SelectGuidanceModal.vue";
export default {
  components: { OrganisationThumbnail, SelectGuidanceModal },
  props: ["visible", "initialTitle"],
  data() {
    return {
      stage: 0,

      selectedOrganisation: null,
      selectedGroupId: null,
      announcementTypeName: null,
      title: "",
      description: "",
      urgent: false,

      isSaving: false,

      isLoadingGroups: false,
      groups: [],

      isLoadingGroupMembers: false,
      groupMembers: [],

      isLoadingAnnouncementPresets: false,
      announcementPresets: [],

      selectedGuidancePackId: null,
      selectedContentId: null,
      selectGuidanceModalVisible: null,
    };
  },
  created() {
    this.selectFirstAdminOrganisation();
  },
  watch: {
    selectedOrganisation(newVal) {
      this.selectedGroupId = null;
      if (newVal) {
        this.loadGroupsForOrg();
        this.loadAnnouncementPresetsForOrg();
      }
    },
    visible(newVal) {
      if (newVal) {
        this.stage = 0;
        this.selectedOrganisation = null;
        this.selectedGroupId = null;
        this.announcementTypeId = null;

        if (this.initialTitle && this.initialTitle.trim().length) {
          this.title = this.initialTitle;
        } else {
          this.title = "";
        }

        this.description = "";
        this.urgent = false;

        this.groups = [];
        this.selectFirstAdminOrganisation();
      }
    },
    selectedGroupId(newVal) {
      this.groupMembers = [];
      if (newVal) {
        this.loadGroupMembers();
      }
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      organisationsICanCreateAnnouncementsFor: "organisationsICanCreateAnnouncementsFor",
      organisationsIAmNotAnAdminFor: "organisationsIAmNotAnAdminFor",
      presences: "presences",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
    }),

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInOrganisation = _.map(this.groupMembers, "presenceId");
      let intersection = _.intersection(
        myPresenceIds,
        presenceIdsInOrganisation
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },

    groupDropdownOptions() {
      let options = _.map(this.groups, (group) => {
        return {
          id: group["id"],
          displayName: group["displayName"],
        };
      });
      return _.orderBy(options, "displayName");
    },

    announcementTypeId() {
      let announcementType = _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
      return announcementType ? announcementType.id : null;
    },

    announcementTypeDescription() {
      if (this.announcementTypeId) {
        let announcementType = _.find(this.announcementPresets, {
          displayName: this.announcementTypeName,
        });
        return announcementType.description;
      }
      return null;
    },

    canAnnounce() {
      if (this.isSharingGuidance && !this.selectedGuidancePackId) {
        return false;
      }
      return (
        this.selectedGroupId !== null &&
        this.announcementTypeId !== null &&
        this.title &&
        this.title.length &&
        this.description &&
        this.description.length
      );
    },

    selectedAnnouncementType() {
      return _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
    },

    choicesFormatted() {
      return _.map(this.selectedAnnouncementType.choices, "name").join(" or ");
    },

    isSharingGuidance() {
      return this.announcementTypeName == "Share Guidance to Staff";
    },

    tenantIdForSelectedOrg() {
      return this.getOrgTenantId(this.selectedOrganisation);
    },
  },
  methods: {
    ...mapActions("announcements", {
      setExpectedNumberOfGroupMembers: "setExpectedNumberOfGroupMembers",
    }),

    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },

    selectOrgById(id) {
      this.selectedOrganisation = _.find(this.organisationsICanCreateAnnouncementsFor, {
        id: id,
      });
    },

    selectFirstAdminOrganisation() {
      if (
        this.organisationsICanCreateAnnouncementsFor.length &&
        !this.selectedOrganisation
      ) {
        this.selectedOrganisation = _.first(this.organisationsICanCreateAnnouncementsFor);
      }
    },

    loadGroupsForOrg() {
      let vm = this;
      vm.isLoadingGroups = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getManagedChatsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingGroups = false;
          vm.groups = r.data;
          vm.selectFirstGroup();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingGroups = false;
          // vm.$message.error("Error loading groups");
        });
    },

    selectFirstGroup() {
      if (this.groups.length && !this.selectedGroup) {
        let selectedGroup = _.first(this.groupDropdownOptions);
        this.selectedGroupId = selectedGroup ? selectedGroup["id"] : null;
      }
    },

    loadGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getGroupMembersForChat(tenantId, this.selectedGroupId)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          // vm.$message.error("Error loading group members");
          console.log(e);
        });
    },

    loadAnnouncementPresetsForOrg() {
      let vm = this;
      vm.isLoadingAnnouncementPresets = true;
      vm.announcementPresets = [];

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      announcements
        .getAnnouncementPresetsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingAnnouncementPresets = false;
          vm.announcementPresets = r.data;
          vm.selectFirstAnnouncementType();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingAnnouncementPresets = false;
          // vm.$message.error("Error loading announcement presets");
        });
    },

    selectFirstAnnouncementType() {
      if (this.announcementPresets.length && !this.announcementTypeId) {
        let announcementType = _.first(this.announcementPresets);
        this.announcementTypeId = announcementType
          ? announcementType["id"]
          : null;
      }
    },

    goToNextStage() {
      if (!this.canAnnounce) {
        return false;
      }
      this.stage += 1;
    },

    goBack() {
      if (this.isSaving) {
        return false;
      }
      this.stage -= 1;
    },

    openSelectGuidanceModal() {
      this.selectGuidanceModalVisible = true;
    },

    closeSelectGuidanceModal() {
      this.selectGuidanceModalVisible = false;
    },

    setGuidance(params) {
      let { guidancePackId, contentId, guidancePackName, contentName } = params;
      this.selectedGuidancePackId = guidancePackId;
      this.selectedContentId = contentId;

      if (contentName) {
        this.title = "Guidance: " + contentName;
      } else {
        this.title = "Guidance: " + guidancePackName;
      }

      this.description =
        'Please review this new or updated content in the "' +
        guidancePackName +
        '" guidance pack';

      this.selectGuidanceModalVisible = false;
    },

    persist() {
      let vm = this;
      vm.isSaving = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      let announcementType = _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });

      let announcementRequest = {
        OwnerId: this.selectedOrganisation.id,
        CreatedBy: this.myPresenceId,
        DisplayName: this.title,
        Choices: announcementType.choices,
        Urgent: this.urgent,
      };

      announcements
        .addAnnouncement(tenantId, announcementRequest)
        .then((r) => {
          // console.log(r);
          vm.setExpectedNumberOfGroupMembers({
            announcementId: r.data.id,
            expectedNumberOfGroupMembers: this.groupMembers.length,
          });
          vm.postTimelineEntry(tenantId, r.data);
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating announcement");
        });
    },

    postTimelineEntry(tenantId, addAnnouncementResponse) {
      let vm = this;

      let timelineMessage;
      if (this.isSharingGuidance) {
        let dollarV = {
          Text: vm.description,
          GuidancePackId: vm.selectedGuidancePackId,
        };
        if (vm.selectedContentId) {
          dollarV["ContentId"] = vm.selectedContentId;
        }

        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          Content: {
            $t: "Soteria.Models.GuidanceChatEntry, Soteria.Models",
            $v: dollarV,
          },
        };
      } else {
        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          Content: {
            $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
            $v: {
              Text: vm.description,
            },
          },
        };
      }

      timelines
        .postTimelineEntry(tenantId, timelineMessage)
        .then(() => {
          vm.confirmAnnouncement(tenantId, addAnnouncementResponse.id);
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating timeline entry");
        });
    },

    confirmAnnouncement(tenantId, announcementId) {
      let vm = this;
      let confirmAnnouncementRequest = {
        AnnouncementId: announcementId,
        Members: null,
        ManagedChats: [this.selectedGroupId],
        Admins: null,
      };
      announcements
        .confirmAnnouncement(tenantId, confirmAnnouncementRequest)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Announcement created successfully");
          vm.$router.push(
            "/sims/announcements/" + tenantId + "/" + announcementId
          );
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error confirming announcement");
        });
    },
  },
};
</script>

<style lang="scss">
.sims-create-announcement-modal {
  .ant-modal-body {
    padding: 0;
  }

  .org-thumbnail {
    display: inline;
    margin-right: 5px;
  }

  .inner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 20px;
  }

  .group-selector {
    width: 100%;
  }

  .urgent-wrapper {
    display: flex;
    display: block;
    margin-top: 15px;
    .urgent-label {
      margin-right: 10px;
    }
  }

  .stage-1-first-p {
    margin-top: 25px;
  }

  .hidden {
    display: none;
  }

  .ant-btn-primary {
    background: #3398c6 !important;
    border-radius: 5px;
    color: #fff;

    &:disabled {
        opacity: 0.7;
    }
  }
}
</style>